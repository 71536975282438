<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="padding: 0px; background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <div class="imageframe-align-center">
            <span
              class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
              ><img
                src="@/assets/RiskManagement/Dust-Explosion-Pentagon-min.png"
                width="400"
                height="400"
                alt=""
                title="dust-explosion-pentagon"
                class="img-responsive wp-image-12601"
            /></span>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Combustible Dust" size="large" />
        <Paragraph
          ><p>
            The reissue of the OSHA Combustible Dust National Emphasis Program
            (NEP) due to the fatal accident at Imperial Sugar has provided
            combustible dust hazards additional exposure throughout all
            industries. SMS provides a wide range of services related to
            combustible dust hazard characterization, prevention, and
            mitigation. These services include dust hazards analysis,
            <router-link
              style="color:#aa222c;"
              to="/testing/sensitivity-testing/dust-explosibility"
              >combustible dust testing</router-link
            >, onsite assessments, OSHA and NFPA compliance assistance, audit
            preparation, training, ignition source evaluation and vent sizing
            calculations. Our process safety professionals have served clients
            in a variety of industries including metal processing, wood/paper
            products, agriculture, food products, textiles, plastics,
            pharmaceuticals and many others.
          </p>
        </Paragraph>
      </div>
    </div>
    <TitleComponent
      title="Combustible Dust Hazard Assessment or Dust Hazards Analysis (DHA)"
    />

    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <Paragraph
          ><p>
            <strong
              ><u>How can SMS help with my combustible dust hazards?</u></strong
            >
          </p>
          <p>
            SMS has been working in the combustible dust industry for over 25
            years. SMS specializes in process and site assessments that are
            based on both compliance with regulations and realistic risk
            reduction methods. SMS philosophy is to provide real solutions that
            are based on sound engineering and safety principles to our clients.
            This includes working closely with the safety and management teams
            of each location or process. For combustible dust, this means
            developing sampling plans, dust testing, gathering process safety
            information, conducting Dust Hazards Analysis, and making
            recommendations that are realistic, supported by data, and targeted
            at improving safety.
          </p>
          <p>
            <strong><u>Who needs a Dust HA?</u></strong>
          </p>
          <p>
            When a dust fails either combustibility or flammability screening
            tests (go/no-go) you are required to complete a Dust Hazards
            Analysis per NFPA 652 or any of the commodity specific regulations
            (i.e. NFPA 484 for metal dusts or NFPA 654 for Combustible
            Particulate Solids).
          </p>
          <p>
            <strong><u>What is a Combustible Dust?</u></strong>
          </p>
          <p>
            Most solid organic materials and metals are combustible when in a
            dust form.&nbsp; This includes sugar, flour, wood, aluminum,
            titanium, etc.
          </p>
          <p>
            Per OSHA, a combustible dust is “a solid material composed of
            distinct particles or pieces, regardless of size, shape, or chemical
            composition, which presents a fire or deflagration hazard when
            suspended in air or some other oxidizing medium over a range of
            concentrations.” For more information click
            <router-link
              style="color:#aa222c;"
              to="/testing/sensitivity-testing/dust-explosibility"
              >here</router-link
            >
            >.
          </p>
          <p>
            <strong><u>How do you conduct a Dust Hazards Analysis?</u></strong>
          </p>
          <ul>
            <li>It must be led by a “qualified person”</li>
            <li>
              Must evaluate the process and areas where fire, flash fire, and
              explosion hazards exist
            </li>
            <li>
              When these hazards exist, the analysis must include documentation
              of
              <ul>
                <li>Safe operating ranges</li>
                <li>
                  Safeguards to manage fire, deflagration, and explosion hazards
                </li>
                <li>
                  Recommendations for additional safeguards where warranted,
                  including a plan for implementation
                </li>
              </ul>
            </li>
            <li>
              Evaluation of both dust and fire/explosion propagation
              <ul>
                <li>Intended and unintended transport of combustible dust</li>
              </ul>
            </li>
            <li>
              Documentation of oxidizing atmosphere and credible ignition
              sources
            </li>
            <li>
              Documentation of all areas that have sufficient quantity of
              combustible dust and potential:
              <ul>
                <li>Oxidizing atmosphere</li>
                <li>Credible Ignition source</li>
                <li>Credible suspension mechanism</li>
              </ul>
            </li>
          </ul>
          <ul>
            <li style="list-style-type: none;"></li>
          </ul>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            <strong
              ><u
                >What Hazard Assessment tools or methods should be used?</u
              ></strong
            >
          </p>
          <p>
            The acceptable methods list in NFPA 652 Appendix B and OSHA 1910.119
            (PSM) include:
          </p>
          <ul>
            <li>“what-if” analysis</li>
            <li>Checklist &amp; what-if (combined) analysis</li>
            <li>Failure Modes and Effects Analysis (FMEA)</li>
            <li>Fault Tree Analysis</li>
            <li>Hazards and Operability Study (HAZOP)</li>
          </ul>
          <p>
            <strong
              ><u
                >What other items need to be addressed at part of a Dust HA?</u
              ></strong
            >
          </p>
          <ul>
            <li>Incident investigations related to combustible dusts</li>
            <li>Electrostatic discharge (ESD) ignition sources</li>
            <li>Sampling plan and locations for collecting dust for testing</li>
            <li>Employee training</li>
            <li>Venting calculations</li>
            <li>
              Process safety programs required by NFPA
              <ul>
                <li>Operating procedures</li>
                <li>Inspection, testing, and maintenance</li>
                <li>Training</li>
                <li>Contractors and contractor training</li>
                <li>Emergency Planning and response</li>
                <li>Incident investigation</li>
                <li>Management of change</li>
                <li>Document retention</li>
                <li>Audits</li>
                <li>Employee participation</li>
              </ul>
            </li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Combustible Dust Hazards Analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has extensive experience with combustible dust hazards analysis (DHA), dust testing, and compliance assistance with NFPA 652 Appendix B and OSHA 1910.119 (PSM)."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
